import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';



const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient,private router: Router) { }

  post(url:string,data:any): Observable<any> {
    return this.http.post(url, data, httpOptions);
  }
  put(url:string,data:any): Observable<any> {
    return this.http.put(url+data.id, data, httpOptions);
  }
  delete(url:string ): Observable<any> {
    return this.http.delete(url ,  httpOptions);
  }
  get(url:string ): Observable<any> {
    return this.http.get(url, httpOptions);
  }
 download(url,id,name){
  let headers = new HttpHeaders();
  headers = headers.set('Accept', 'application/pdf');
  this.http.post(url, {
    id: id,
    name: name
  }, { headers: headers, responseType: 'blob' }).subscribe(
    data => {
      var fileURL = URL.createObjectURL(data);
      window.open(fileURL);
    }
  );

 }
}
