import { Injectable } from '@angular/core';
import { Router } from '@angular/router'; 
import { UserProfile } from '../core/user-profile.model'; 

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private router: Router ) { }

  logout() { 
    this.router.navigate(['']);
  }

  isLoggedIn() { 
  }

  

  async routeOnLogin() {   
  }

  resetPwd(mail: string){ 
  }
}
