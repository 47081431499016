import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../_services/auth.service';
import { TokenStorageService } from '../_services/token-storage.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: any = {};
  isLoggedIn = false;
  isSignUp=false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];
  showProgressBar:false;

  constructor(private authService: AuthService, private tokenStorage: TokenStorageService) { }

  ngOnInit() {
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.roles = this.tokenStorage.getUser().roles;
    }
  }

  onSubmit(form: NgForm) {
    const { email, password, firstName, lastName } = form.value;
    this.authService.login({ username: email, password: password }).subscribe(
      data => {
        if (data.authUser > 0) {
          this.tokenStorage.saveToken(data.authToken);
          this.tokenStorage.saveUser(data);

          this.isLoginFailed = false;
          this.isLoggedIn = true;
          this.roles = this.tokenStorage.getUser().roles;
          console.log("roles::",this.roles);
          this.errorMessage ="";
          this.authService.routeOnLogin();
        } else { 
          this.errorMessage =data[0];
        }

      },
      err => {
        this.errorMessage = "Une erreur est survenue!";
        this.isLoginFailed = true;
      }
    );
  }

  reloadPage() {
    window.location.reload();
  }
  setLanguage(){}
}
