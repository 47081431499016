import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'teck-dialog-elements',
  templateUrl: 'tech-dialog-elements.html',
})
export class TechDialogElements {

  private arMsg :string[]=[] ;


  constructor(@Inject(MAT_DIALOG_DATA) public data) {
    console.log("data :: ",data)
    this.arMsg=Object.keys(data.message).map(key => data.message[key]);

  }
}
